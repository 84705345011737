<template>
  <div
    style="width: 100%; height: 100%;"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'KeyboardShortcutResponder',
  props: {
  },
  data: function() {
    return { }
  },
  components: {
  },
  methods: {
    globalListener: function (event) {
      if (event.keyCode === 114 || ((event.ctrlKey || event.metaKey) && event.keyCode === 70)) { 
        event.preventDefault();
        this.searchCommand();
      }
      if (event.keyCode === 27) {
        this.exitCommand();
      }
      if (event.keyCode === 13) {
        this.enterCommand();
      }
    },
    searchCommand: function () {
      this.$emit("search-command-triggered")
    },
    exitCommand: function () {
      this.$emit("exit-command-triggered")
    },
    enterCommand: function () {
      this.$emit("enter-command-triggered")
    }
  },
  created() {
    window.addEventListener("keydown", this.globalListener);
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stack {
  display: flex;
  gap: 10px;
}
.vertical {
  flex-direction: column;
  align-items: center;
  height: 100%;
}
</style>
