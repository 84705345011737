<template>
  <div
    :style="styleObject"
    :class="classObject"
    class="stack"
  >
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'BaseStack',
  props: {
    direction: {
      default: "vertical",
      type: String
    },
    gap: {
      default: 10,
      type: Number
    }
  },
  data: function() {
    return {
      styleObject: {
        width: this.width,
        gap: this.gap + 'px'
      },
      classObject: {
        vertical: this.direction === "vertical",
        horizontal: this.direction === "horizontal"
      }
    }
  },
  components: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.stack {
  display: flex;
  gap: 10px;
}
.stack.horizontal {
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
.stack.vertical {
  flex-direction: column;
  align-items: center;
  height: 100%;
}
</style>
