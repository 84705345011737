<template>
  <h-stack
    style="align-items: center; width: auto;"
  >
    <text-label 
      style="opacity: 0.4"
      hierachy="caption"
    >{{ action }}</text-label>
    <text-label
      style="opacity: 0.4;"
      hierachy="caption"
      v-for="(k, i) in formatedKeys"
      :key="k.title+i"
      :class="getClass(k.type)"
    >{{ k.title }}</text-label>
  </h-stack>
</template>

<script>
import TextLabel from './TextLabel.vue'
import HStack from './HStack.vue'

export default {

  name: 'ShortcutHint',
  props: {
    action: {
      default: "",
      type: String
    },
    keys: {
      default: () => [],
      type: Array
    }
  },
  data() {
    return {}
  },
  computed: {
    formatedKeys: function() {
      var r = []
      var k = this.keys.join(',+,').split(',')
      for (var i=0; i<k.length; i++) {
        if (i%2 == 0)  { r.push({title: k[i], type: 'key',  index: i}) }
        else      { r.push({title: k[i], type: 'text', index: i}) }
      }
      return r
    }
  },
  methods: {
    getClass: function(type) {
      if (type === 'key') return {key: true}
      else return {}
    }
  },
  components: {
    TextLabel,
    HStack
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.key {
  background: #ddd;
  padding: 5px;
  border-radius: 2px;
  box-shadow: 1px 1px 1px 0px #aaa;
}
</style>
