<template>
  <base-stack
    direction="horizontal"
    :gap="gap"
  >
    <slot></slot>
  </base-stack>
</template>

<script>
import BaseStack from './BaseStack.vue'

export default {
  name: 'HStack',
  props: {
    gap: {
      default: 10,
      type: Number
    }
  },
  data: function() {
    return {
    }
  },
  components: {
    BaseStack
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
