<template>
  <div class="button push" :class="classObject">
    <slot></slot>
  </div>
</template>

<script>

export default {
  name: 'PushButton',
  props: {
    active: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    classObject: function() {
      return {
        active: this.active
      }
    }
  },
  components: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.button {
  cursor: pointer;
  min-height: 30px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  user-select: none;

  background: #fff;

  border-color: transparent;
  border-style: solid;
  border-width: 1px;

  padding: 5px 20px 5px 20px;

  border-color: #f1f1f1;
  border-style: solid;
  border-width: 1px;

  border-radius: 10px;
}

.button:hover {
  background: #f1f1f150;
}

.button.active {
  background: #f1f1f1;
}
</style>
