<template>
  <img loading="lazy" :src="url" :width="width" :height="height" :alt="alt"/>
</template>

<script>

export default {
  name: 'ImagePreview',
  props: {
    url: String,
    width: {
      default: 64,
      type: Number
    },
    height: {
      default: 64,
      type: Number
    },
    alt: String
  },
  data() {
    return {}
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
