<template>
  <div
    class="grid-item"
    :class="{'active': active}"
    :style="styleObject"
  >
    <slot>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'GridItem',
  props: {
    active: {
      default: false,
      type: Boolean
    },
    padding: {
      default: 20,
      type: Number
    },
    width: {
      default: 100,
      type: Number
    },
    height: {
      default: 120,
      type: Number
    }
  },
  data: function () {
    return {
      styleObject: {
        padding: this.padding + 'px',
        width: this.width + 'px',
        height: this.height + 'px',
        minWidth: this.width + 'px',
        minHeight: this.height + 'px'
      }
    }
  },
  methods: {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.grid-item {
  cursor: pointer;
  border-width: 0 0 1px 0;
  border-style: solid;
  border-color: #f1f1f1;
}

.grid-item:hover {
  background: #f1f1f1;
}

.grid-tem.active {
  background: #f1f1f1;
}
</style>
