<template>
  <keyboard-shortcut-responder
    @search-command-triggered="searchCommandHandler()"
    @exit-command-triggered="exitCommandHandler()"
    @enter-command-triggered="enterCommandHandler()"
  >
    <h-stack 
      :gap="1"
      style="height: 100%; min-height: 700px; min-width: 800px;"
      :class="{'searching': isInSearchMode}"
    >
      <!-- sidebar -->
      <v-stack
        :gap="1"
        style="height: 100%; min-width: 200px; max-width: 200px; width: 0;"
        class="side-bar grided"
      >
        <global-header
          style="align-self: stretch; justify-self: stretch; padding: 80px 10px 20px 10px;"
          class="grided"
        >
        </global-header>
        <!-- sidebar -->
        <z-stack
          style="flex-grow: 1; align-self: stretch;"
        >
          <vertical-scroll-view
            class="side-navigator"
          >
            <vertical-list-navigator 
              class="category-filter"
              :items="categories"
              :activeItemIndices="activeCategoryIndices"
              style="min-width: 120px; align-items: center; align-self: stretch; overflow-x: visible; padding: 10px;"
              @on-click="onCategoryFilterChange"
            ></vertical-list-navigator>
          </vertical-scroll-view>
        </z-stack>
        <v-stack
          style="height: auto; align-items: flex-start; align-self: stretch; padding: 10px;"
          class="grided"
        >
          <shortcut-hint
            action="搜索"
            :keys="['Cmd/Ctl','F']"
          >
          </shortcut-hint>
          <shortcut-hint
            action="关闭搜索"
            :keys="['Esc']"
          >
          </shortcut-hint>
        </v-stack>
        <global-footer 
          style="text-align: left; padding: 10px 10px 40px 10px;"
          class="grided"
        >
        </global-footer>
      </v-stack>
      <!-- end of sidebar -->
      <!-- content -->
      <v-stack :gap="1" style="flex-grow: 1;">
        <!-- search bar -->
        <h-stack :gap="50" class="grided" style="box-sizing: border-box; padding: 10px;">
          <push-button
            style="align-self: center; padding: 10px; min-height: 0px; border-radius: 5px;"
            :style="{visibility: (isInSearchMode ? 'visible' : 'hidden')}"
            @click="exitCommandHandler"
          >
            <h-stack>
              <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.646447 3.64645C0.451184 3.84171 0.451184 4.15829 0.646447 4.35355L3.82843 7.53553C4.02369 7.7308 4.34027 7.7308 4.53553 7.53553C4.7308 7.34027 4.7308 7.02369 4.53553 6.82843L1.70711 4L4.53553 1.17157C4.7308 0.976311 4.7308 0.659728 4.53553 0.464466C4.34027 0.269204 4.02369 0.269204 3.82843 0.464466L0.646447 3.64645ZM7.16667 11.5C6.89052 11.5 6.66667 11.7239 6.66667 12C6.66667 12.2761 6.89052 12.5 7.16667 12.5V11.5ZM14.5 8C14.5 9.933 12.933 11.5 11 11.5V12.5C13.4853 12.5 15.5 10.4853 15.5 8H14.5ZM11 4.5C12.933 4.5 14.5 6.067 14.5 8H15.5C15.5 5.51472 13.4853 3.5 11 3.5V4.5ZM11 3.5H1V4.5H11V3.5ZM11 11.5H7.16667V12.5H11V11.5Z" fill="black"/>
              </svg>
              <text-label hierachy="paragraph">返回</text-label>
            </h-stack>
          </push-button>
          <text-input
            class="search-bar"
            style="flex-grow: 1; justify-content: flex-end;"
            :value="searchString"
            :focus="isInSearchMode"
            :placeholder="'输入搜索关键词'"
            @on-input="searchInputHandler"
            @on-focus="searchFocusHandler"
          >
            <drop-down
              :options="['全部', '软件', '硬件']"
              :defaultValue="'全部'"
              @on-select="globalFilterSelectionHandler"
            >
            </drop-down>
          </text-input>
        </h-stack>
        <!-- search bar -->
        <!-- height: 0 is a trick to allow child scollers to fill the empty space vertically. -->
        <h-stack
          style="flex-grow: 1;  height: 0;"
        >
          <z-stack
            style="flex: 1;"
          >
            <v-stack
              style="width: 100%; height: 100%; justify-content: center;"
              v-if="isInSearchMode && (iconsInDisplay === null || iconsInDisplay.length === 0)"
            >
              <text-label v-if="searchString.length > 0" hierachy="caption">没有找到你想要的图标</text-label>
              <text-label v-else hierachy="caption">请输入搜索关键词</text-label>
            </v-stack>
            <vertical-scroll-view
              style="flex: 1; align-self: stretch; justify-self: stretch;"
              ref="gridContainer"
              @on-scroll="contentScrollHandler"
            >
              <lazy-grid
                style="flex: 1; align-self: stretch; justify-self: stretch;"
                :items="iconsInDisplay"
                :scrollTop="contentScrollTop"
                @on-select-item="itemSelectionHandler"
              >
              </lazy-grid>
            </vertical-scroll-view>
          </z-stack>
          <!-- start of side panel -->
          <v-stack
            class="grided"
          >
            <SVG-editor
              layout="vertical"
              :item="activeGridItem"
            >
            </SVG-editor>
          </v-stack>
          <!-- end of side panel -->
        </h-stack>
      </v-stack>
      <!-- end of content -->

    </h-stack>
  </keyboard-shortcut-responder>
</template>

<script>
import HStack from '../components/HStack.vue'
import VStack from '../components/VStack.vue'
import VerticalListNavigator from '../components/VerticalListNavigator.vue'
import LazyGrid from '../components/LazyGrid.vue'
import VerticalScrollView from '../components/VerticalScrollView.vue'
import GlobalHeader from '../components/GlobalHeader.vue'
import GlobalFooter from '../components/GlobalFooter.vue'
import KeyboardShortcutResponder from '../components/KeyboardShortcutResponder.vue'
import TextInput from '../components/TextInput.vue'
import ShortcutHint from '../components/ShortcutHint.vue'
import SVGEditor from '../components/SVGEditor.vue'
import ZStack from '../components/ZStack.vue'
import DropDown from '../components/DropDown.vue'
import TextLabel from '../components/TextLabel.vue'
import PushButton from '../components/PushButton.vue'

const settings = require('/site.config.js')

export default {
  name: 'HomeScene',
  props: {
    width: {
      default: "100%",
      type: String
    },
    height: {
      default: "100%",
      type: String
    }
  },
  data: function() {
    return {
      styleObject: {
        width: this.width,
        height: this.height
      },
      icons: null,
      iconsArray: [],
      categories: null,
      activeCategoryIndices: [],
      searchString: '',
      contentScrollTop: null,
      activeGridItem: null,
      usageScenarioFilter: '全部',
      isInSearchMode: false,
    }
  },
  computed: {
    usageScenarioFilterRegex: function () {
      switch (this.usageScenarioFilter) {
        case '硬件':
          return /硬件/
        case '软件':
          return /^((?!硬件).)*$/
        case '全部':
        default: 
          return /^.*$/
      }
    },
    iconsInDisplay: function () {
      if (this.icons === null) return null

      var result = []

      // if category filter has been set, pick related icons
      // else give all icons
      const l = this.activeCategoryIndices && this.activeCategoryIndices.map(i => this.icons[this.categories[i].id])
      const iconsROI = l.length > 0 && l.reduce((p, c) => p.concat(c)) || this.iconsArray
      
      if ( this.isInSearchMode ) {
        if ( this.searchString.length !== 0 ) {
          result = iconsROI.filter(icon => {
            
            const regList = [new RegExp(this.searchString.trim()), this.usageScenarioFilterRegex]
            return regList.every(rx => rx.test(icon.keywordsString))
          })
        }
      } else {
        result = iconsROI.filter(icon => {
          return this.usageScenarioFilterRegex.test(icon.keywordsString)
        }) 
      }

      return result
    }
  },
  methods: {
    onSearching() {
      this.updateCategoryCountLabel()
    },
    onExitSearching() {
      this.updateCategoryCountLabel()
    },
    updateCategoryCountLabel() {
      if (this.icons === null) return
      
      if (this.isInSearchMode) {
        const counts = []
        for (const i of this.iconsInDisplay) {
          if (i.category in counts) counts[i.category] += 1
          else counts[i.category] = 1
        }
        this.categories.map(c => {
          c.title = `${c.id} (${counts[c.id] || 0})`
        })
      } else {
        const counts = []
        for (const c in this.icons) {
          counts[c] = this.icons[c] && this.icons[c].filter(icon => {
            return this.usageScenarioFilterRegex.test(icon.keywordsString)
          }).length
        }
        this.categories.map(c => c.title = `${c.id} (${counts[c.id] || 0})`)
      }
    },
    onCategoryFilterChange(category, index, categories) {
      category, categories

      if (this.isInSearchMode) {
        // deselect if click on the same category
        if (this.activeCategoryIndices.includes(index)) {
          this.activeCategoryIndices = this.activeCategoryIndices.filter(i => i !== index)
        } else {
          this.activeCategoryIndices.push(index)
        }
      } else {
        // no multiselection required
        this.activeCategoryIndices = [index]
      }
    },
    searchCommandHandler() {
      // do nothing if already in search mode
      if (this.isInSearchMode) return

      // set focus on the input field
      this.isInSearchMode = true
      this.savedActiveCategoryIndices = this.activeCategoryIndices
      this.activeCategoryIndices = []

      this.onSearching()
    },
    searchInputHandler(event) {
      this.searchString = event.target.value
      this.updateCategoryCountLabel()
    },
    searchFocusHandler() {
      // keep state variable syncronized with native
      this.searchCommandHandler()
    },
    exitCommandHandler() {
      
      if (this.isInSearchMode) {
        this.searchString = ''
        // reset focus
        this.isInSearchMode = false
        // reset active category
        this.activeCategoryIndices = this.savedActiveCategoryIndices
        delete this.savedActiveCategoryIndices
        
        this.onExitSearching()
      }
    },
    enterCommandHandler() {
    },
    itemSelectionHandler(item) {
      this.activeGridItem = item
    },
    globalFilterSelectionHandler(selection) {
      this.usageScenarioFilter = selection
      this.updateCategoryCountLabel()
    },
    contentScrollHandler(event) {
      this.contentScrollTop = event.target.scrollTop
    }
  },
  created: function () {
    // load catagory list
    fetch(settings.apis.listCategories.url)
      .then( response => response.json() )
      .then(data => {
        this.categories = data.result.map(c => { return { title: c, id: c} })

        // set the first category as active by default
        this.activeCategoryIndices = [ 0 ]

        this.icons = {}
        // load image list
        for (const category of this.categories) {
          this.icons[category.id] = null

          const requestUrl = new URL(settings.apis.list.url)
          requestUrl.searchParams.append("category", category.id)
          fetch(requestUrl)
            .then(data => data.json())
            .then(json => {
              this.icons[category.id] = json.result
              this.icons[category.id].map(icon => icon.keywordsString = icon.keywords.join() )
              this.iconsArray = this.iconsArray.concat(this.icons[category.id])

              this.updateCategoryCountLabel()
            })
        }
      })
  },
  mounted: function () {
  },
  unmounted: function () {
  },
  components: {
    HStack,
    VStack,
    ZStack,
    VerticalListNavigator,
    LazyGrid,
    VerticalScrollView,
    GlobalHeader,
    GlobalFooter,
    KeyboardShortcutResponder,
    TextInput,
    ShortcutHint,
    SVGEditor,
    DropDown,
    TextLabel,
    PushButton
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.grided {
  outline: 1px solid #f1f1f1;
}

.padding-leading {
  width: calc(100% - 40px); 
  padding-left: 40px; 
}

.padding-horizontal {
  width: calc(100% - 80px); 
  padding-left: 40px; 
  padding-right: 40px;
}

.search-input-background {
  position: absolute; 
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px; 
  padding-right: 100px; 
  right: 0px;
  top: 0px;
  border-radius: 0 0 0 20px;
  z-index: 3;
  backdrop-filter: blur(5px);
}

/* input animation */
.search-bar >>> .input {
  transition: all 0.1s linear;
  cursor: pointer;
}

.searching >>> .input {
  flex-grow: 1;
}


.searching >>> .category-filter > .button.push.active::after {
  content: '';
  width: 12px;
  height: 12px;
  position: absolute;

  right: 20px;

	background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L11 11M11 1L1 11' stroke='%23CCCCCC' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
}
/* sidebar animation */
</style>
