<template>
  <div
    class="stack"
  >
    <slot>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'ZStack',
  props: {
  },
  data: function() {
    return {
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.stack {
  display: block;
  position: relative;

  overflow: hidden;
}

:slotted(*) {
  position: absolute;
  width: 100%;
  height: 100%;
}

</style>
