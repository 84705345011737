<!--
items: [
  {
    title: String,
    id: String
  },
  …
]

activeItemIndices: [ 0, 1, 2, … ]
-->

<template>
  <v-stack
    style="height: auto;"
    class="rounded"
    :gap="10"
  >
    <push-button 
      style="width: auto; align-self: stretch;"
      v-for="(item, index) in items" 
      :key="item.id"
      :active="activeItemIndices.includes(index)"
      @click="onclick(item, index)" 
    >
      <text-label hierachy="paragraph"> {{ item.title }} </text-label>
    </push-button>
    <filling-spacer></filling-spacer>
  </v-stack>
</template>

<script>
import TextLabel from './TextLabel.vue'
import PushButton from './PushButton.vue'
import VStack from './VStack.vue'
import FillingSpacer from './FillingSpacer.vue'

export default {
  name: 'VerticalListNavigator',
  props: [
    "items",
    "activeItemIndices"
  ],
  data: function () {
    return {
    }
  },
  computed: {
  },
  methods: {
    onclick: function (item, index) {
      this.$emit('on-click', item, index, this.items)
    }
  },
  created: function () {
  },
  mounted: function () {
  },
  unmounted: function () {
  },
  components: {
    TextLabel,
    FillingSpacer,
    PushButton,
    VStack
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.button {
  border: none;
}

.default-background {
  background: #fff;
}
</style>
