<template>
  <div>
    <div class="color-picker">
    </div>
  </div>
</template>

<script>
import '@popobe97/gpickr/dist/gpickr.min.css'
import { Pickr } from '@popobe97/gpickr/dist/gpickr.min.js'


/**
 * Author: Simonwep@github
 * Tries to convert a color name to rgb/a hex representation
 * @param name
 * @returns {string | CanvasGradient | CanvasPattern}
 */
function color2Hex(name) {
    // Since invalid color's will be parsed as black, filter them out
    if (typeof name === 'string' && name.toLowerCase() === 'black') {
        return '#000000';
    }
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = name;
    return ctx.fillStyle === '#000000' ? null : ctx.fillStyle;
}

/**
 * Author: Ruiyao@github
 * convert opacity to hex string
 * @param opacity
 * @returns String
 */
function opacity2Hex(opacity) {
  opacity = Math.max(0.0, Math.min(opacity, 1.0))
  return Math.round(opacity * 255).toString(16).toUpperCase().padStart(2, '0')
}

export default {
  name: 'ColorPicker',
  props: {
    color: {
      default: null
    },
    opacity: {
      default: null
    }
  },
  watch: {
    opacity: function() {
      if (this.color === null) { 
        this.picker.setColor(null) 
      } else {
        this.picker.setColor(`${color2Hex(this.color)}${opacity2Hex(this.opacity)}`)
      }
    },
    color: function() {
      if (this.color === null) { 
        this.picker.setColor(null)
      } else {
        this.picker.setColor(`${color2Hex(this.color)}${opacity2Hex(this.opacity)}`)
      }
    }
  },
  data: function() {
    return {
      picker: null
    }
  },
  methods: {
    onPickerInit: function() {
      if (this.color === null) { 
        this.picker.setColor(null) 
      } else {
        this.picker.setColor(`${color2Hex(this.color)}${opacity2Hex(this.opacity)}`)
      }
    },
    onPickerChange: function(color) {
      let c = color.toRGBA()
      let rgb = c.slice(0, 3).map(e => parseInt(e))
      let alpha = c[c.length-1].toFixed(2)

      let rgba = rgb.concat(alpha)

      let hex = '#'+rgb.map(e=>e.toString(16).padStart(2, '0')).join('')
      
      this.picker.applyColor()
      
      this.$emit('color-change', rgba, hex)
    }
  },
  mounted: function() {
    this.picker = Pickr.create({
      el: '.color-picker',
      theme: 'nano', // or 'monolith', or 'nano'
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,
        default: null,
        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          input: true
        }
      }
    })


    this.picker.on('change', this.onPickerChange)

    this.picker.on('init', this.onPickerInit)
  },
  unmounted: function() {
    this.picker && this.picker.destroyAndRemove()
  },
  components: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
:global(.pcr-interaction) {
  padding: 0 .6em .6em .6em;
}

:global(.pcr-selection) {
  padding: .6em;
}
:global(.gpickr.pcr-app) {
  backdrop-filter: blur(4px);
  background: rgba(255, 255, 255, 0.6);

  border-radius: 5px;
  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
  box-shadow: 0 0 2px 2px rgba(100, 100, 100, 0.05);
  backdrop-filter: blur(4px);
}

:global(.pcr-button) {
  border-width: 1px;
  border-style: solid;
  border-color: #ccc;
}
</style>