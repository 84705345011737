<template>
  <div>
    <div 
      ref="button"
      class="gpcr pickr"
    >
      <button 
        type="button" 
        class="pcr-button" 
        :class="buttonClassObject"
        :style="buttonStyleObject"
        role="button" 
        aria-label="toggle color picker dialog"
      >
      </button>
    </div>

    <!-- following  will be automatically attached to .pcr-app -->
    <div ref="pcr" class="gpcr-pcr">
    </div>
    <!-- following dom will be automatically attached to .pcr-app -->
    <!-- gradient -->
    <div ref="interaction" class="gpcr-interaction">
      <!-- result -->
      <div ref="result" class="gpcr-result" :style="resultStyleObject" @mousedown="onAngleTap($event)" @touchstart="onAngleTap($event)">
        <!-- mode -->
        <div ref="mode" :data-mode="currentMode" class="gpcr-mode"  @mousedown="onModeTap($event)" @touchstart="onModeTap($event)"></div>
          <!-- angle -->
          <div ref="angle" class="gpcr-angle" :style="angleStyleObject">
              <!-- arrow -->
              <div ref="arrow" :style="arrowStyleObject"></div>
          </div>
          <!-- pos -->
          <div ref="pos" class="gpcr-pos" :style="posStyleObject">
            <div v-for="(d, i) in radialPositions" :class="{'gpcr-active': d === radialPosition}" :data-pos="d" :key="`gpickr-pos-${i}`"  @click="onCircleTap($event)"></div>
          </div>
        </div>
        <!-- stops -->
        <div ref="stops" class="gpcr-stops">
          <!-- preview -->
          <div 
            ref="stopPreview" 
            class="gpcr-stop-preview"
            :style="stopPreviewStyleObject"
            @click="onStopPreviewTap($event)"
          ></div>
          <!-- markers -->
          <div ref="markerContainer" class="gpcr-stop-markers">
            <div 
              v-for="(s, i) in this.currentStops"
              class="gpcr-marker"
              :class="{'gpcr-active': this.focusedMarker && this.elements.markers[i] === this.focusedMarker}"
              :style="getMarkerStyleObject(i)"
              :key="`gpcr-marker-${i}`"
              ref="marker"
              @mousedown="onMarkerTap($event, i)"
              @touchstart="onMarkerTap($event, i)"
            ></div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import '@popobe97/gpickr/dist/gpickr.min.css'
import GPickr from '@popobe97/gpickr/dist/gpickr.min.js'

const Pickr = GPickr.Pickr

/**
 * Author: Simonwep@github
 * Tries to convert a color name to rgb/a hex representation
 * @param name
 * @returns {string | CanvasGradient | CanvasPattern}
 */
function color2Hex(name) {
    // Since invalid color's will be parsed as black, filter them out
    if (typeof name === 'string' && name.toLowerCase() === 'black') {
        return '#000';
    }
    const ctx = document.createElement('canvas').getContext('2d');
    ctx.fillStyle = name;
    return ctx.fillStyle === '#000' ? null : ctx.fillStyle;
}

/**
 * Author: Simonwep@github
 * Tries to extract mouse/touch position from event
 * @param event
 * @returns {x: event.clientX, y: event.clientY}
 */
function getMousePositionFromEvent(event) {
  const tap = (event.touches && event.touches[0] || event);
  return {
    x: tap.clientX,
    y: tap.clientY,
  }
}
/**
 * Author: Ruiyao@github
 * convert opacity to hex string
 * @param opacity
 * @returns String
 */
function opacity2Hex(opacity) {
  opacity = Math.max(0.0, Math.min(opacity, 1.0))
  return Math.round(opacity * 255).toString(16).toUpperCase().padStart(2, '0')
}

export default {
  name: 'GradientPicker',
  emits: ['init', 'gradient-change'],
  props: {
    stops: {
      default: () => {
        return [
          { color: '#42445a', offset: "0%", opacity: 1.0 },
          { color: '#20b6dd', offset: "100%", opacity: 1.0 }
        ]
      },
      type: Array
    },
    supportConic: {
      default: false,
      type: Boolean
    },
    angle: {
      default: 0,
      type: Number
    },
    mode: {
      default: 'linear',
      type: String
    },
    option: {
      default: function() {
        return {
          angleAdjustment: true
        }
      }
    }
  },
  watch: {
    currentStops: function() {
      this.notifyChangeToParent()
    },
    currentAngle: function() {
      this.notifyChangeToParent()
    },
    currentMode: function() {
      this.notifyChangeToParent()
    },
    radialPosition: function() {
      this.notifyChangeToParent()
    },
    focusedMarker: function() {
      if (this.focusedMarker === null) { return }
      // update picker color on change
      const idx = this.elements.markers.indexOf(this.focusedMarker)
      const {color, opacity} = this.currentStops[idx]
      this.picker.setColor(`${color}${opacity2Hex(opacity)}`)
    },
    stops: function() {
      // update component
      this.currentStops = structuredClone(this.stops)
    }
  },
  data: function() {
    return {
      // Gradient stops
      focusedMarker: null,
      angles: [
        { angle: 0,   name: 'to top'},
        { angle: 90,  name: 'to right'},
        { angle: 180, name: 'to bottom'},
        { angle: 270, name: 'to left'}
      ],
      // Radial radialPosition
      radialPosition  : 'circle at center',
      radialPositions : [
        'circle at left top',
        'circle at center top',
        'circle at right top',
        'circle at left',
        'circle at center',
        'circle at right',
        'circle at left bottom',
        'circle at center bottom',
        'circle at right bottom'
      ],
      // Linear Angle
      currentAngle: null,
      // gradient stops
      currentStops: null,
      currentMode : null,
      modes : ['linear', 'radial'],
      picker : null
    }
  },
  computed: {
    gradientStyleString: function() {
      if (this.gradientStopString === null) { return null }
      switch (this.currentMode) {
        case 'linear':
          return `linear-gradient(${this.currentAngle}deg,  ${this.gradientStopString})`
        case 'radial':
          return `radial-gradient(${this.radialPosition}, ${this.gradientStopString})`
        case 'conic':
          return `conic-gradient(${this.gradientStopString})`
        default:
          return null
      }
    },
    gradientStopString: function() {
      if (this.currentStops === null) { return null }
      switch (this.currentMode) {
        case 'linear':
        case 'radial':
          return this.currentStops.map(s => `${color2Hex(s.color)+opacity2Hex(s.opacity)} ${s.offset}`).join(',')
        case 'conic':
          return this.currentStops.map(s => `${color2Hex(s.color)+opacity2Hex(s.opacity)} ${parseInt(parseInt(s.offset)/100*360)}deg`).join(',')
        default:
          return null
      }
    },
    elements: function() {
      return {
        button:           this.$refs.button,
        pcr:              this.$refs.pcr,
        interaction:      this.$refs.interaction,
        mode:             this.$refs.mode,
        arrow:            null,
        stopPreview:      this.$refs.stopPreview,
        result:           this.$refs.result,
        pos:              this.$refs.pos,
        angle:            this.$refs.angle,
        markerContainer:  this.$refs.markerContainer,
        markers:          this.$refs.marker
      }
    },
    buttonStyleObject: function() {
      return {
        '--pcr-color': this.gradientStyleString
      }
    },
    buttonClassObject: function() {
      return {
        clear: this.currentStops === null,
      }
    },
    resultStyleObject: function() {
      return {
        background: this.gradientStyleString
      }
    },
    stopPreviewStyleObject: function() {
      return {
        background: `linear-gradient(to right, ${this.gradientStopString})`
      }
    },
    arrowStyleObject: function () {
      return {
        transform: `rotate(${(this.currentAngle - 90).toFixed(2)}deg)`
      }
    },
    posStyleObject: function() {
      return {
        // clear style if it's radial, otherwise set to hidden
        opacity: this.currentMode === 'radial' ? null : '0',
        visibility: this.currentMode === 'radial' ? null : 'hidden'
      }
    },
    angleStyleObject: function() {
      return {
        // clear style if it's radial, otherwise set to hidden
        opacity: this.currentMode === 'linear' ? null : '0',
        visibility: this.currentMode === 'linear' ? null : 'hidden'
      }
    }
  },
  methods: {
    onPickerChange: function(color) {
      if (this.focusedMarker !== null) {
        const idx = this.elements.markers.indexOf(this.focusedMarker)
        // break components into rgb and a
        const c = `#${color.toHEXA().slice(0, 3).join('')}`
        const o = color.toRGBA().pop()

        // reduce calling frequency
        if (c !== this.currentStops[idx].color || o !== this.currentStops[idx].color) {
          this.currentStops[idx].color   = c
          this.currentStops[idx].opacity = o
          // trigger reactivity system
          this.currentStops = structuredClone(this.currentStops)
        }
      }
    },
    onPickerInit: function() {
      this.rearrangeElements()
      // focus the first marker on init
      this.focusedMarker = this.elements.markers[0]
      // emit init event to parent component
      this.$emit('init', this)
    },
    onMarkerTap: function(event, markerIdx) {
      const marker = event.target
      // remove marker when user drag marker over 50 px in y
      var shouldRemoveMarker = false

      // prevent from showing an I-beam cursor
      event.preventDefault()

      const markerContainerRect = this.elements.markerContainer.getBoundingClientRect()

      this.focusedMarker = marker

      // slide on mouse/touch move
      const onMove = (moveEvent) => {
        const {x, y} = getMousePositionFromEvent(moveEvent)
        const dy = Math.abs(y - markerContainerRect.y)
        const dx = x - markerContainerRect.left

        const newOffset  = `${Math.round(Math.max(Math.min(dx / markerContainerRect.width, 1.0), 0.0) * 100)}%`

        // make sure markers do not overrun each other
        const lo = markerIdx - 1 >= 0 && parseInt(this.currentStops[markerIdx-1].offset) || -1
        const ro = markerIdx + 1 < this.currentStops.length && parseInt(this.currentStops[markerIdx+1].offset) || 101
        const o = parseInt(newOffset)
        if (!(o > lo && o < ro)) { return }

        // set marker pisition
        marker.style.left = newOffset

        // Allow the user to remove the current stop with trying to drag the stop away
        shouldRemoveMarker = dy > 50 && this.currentStops.length > 2
        // Temperorily hide marker. 
        // Remove when touch/mouse move ends
        marker.style.opacity = shouldRemoveMarker ? '0.0' : '1.0'

        if (!shouldRemoveMarker) {
          // emit gradient change event 
          if (newOffset !== this.currentStops[markerIdx].offset) {
            this.currentStops[markerIdx].offset = newOffset
            // trigger reactivity system
            this.currentStops = structuredClone(this.currentStops)
          }
        }
      }

      ;['mousemove', 'touchmove'].map(moveEventName => {
        // listen on window to follow event happens outside element
        window.addEventListener(moveEventName, onMove)
      })

      // touch/mouse end
      const onEnd = (endEvent) => {
        endEvent
        // remove moving event listener
        ;['mousemove', 'touchmove'].map(moveEventName => {
          window.removeEventListener(moveEventName, onMove)
        })
        ;['mouseup', 'touchend', 'touchcancel'].map(endEventName => {
          window.removeEventListener(endEventName, onEnd)
        })
        if (shouldRemoveMarker) {
          // remove stop
          this.currentStops.splice(markerIdx, 1)

          // focus another marker in case the current one was removed
          if (marker === this.focusedMarker) {
            const prev = markerIdx - 1 < 0 ? 0 : markerIdx - 1
            this.focusedMarker = this.elements.markers[prev]
          }
        }
      }

      ;['mouseup', 'touchend', 'touchcancel'].map(endEventName => {
        // listen on window to follow event happens outside element
        window.addEventListener(endEventName, onEnd)
      })
    },
    onModeTap: function(event) {
      const ni = (this.modes.indexOf(this.currentMode) + 1) % this.modes.length
      this.currentMode = this.modes[ni]
      // Prevent some bizzar things
      event.stopPropagation()
    },
    onStopPreviewTap: function(event) {
      // add new stop
      event
    },
    onAngleTap: function(event) {
      // adjust gradient angle
      // only linear gradient support angle alternation
      if (this.currentMode !== 'linear') { return }

      if (this.option.angleAdjustment === false) { return }

      event.preventDefault()

      const onMove = (moveEvent) => {
        const {x, y} = getMousePositionFromEvent(moveEvent)
        const box = this.elements.angle.getBoundingClientRect()

        // calculate angle relative to the center
        const cx = box.left + box.width / 2
        const cy = box.top + box.height / 2
        const radians = Math.atan2(x - cx, y - cy) - Math.PI
        const degrees = Math.abs(radians * 180 / Math.PI)

        // ctrl and shift can be used 
        const div = [45, 1][Number(event.shiftKey)]

        const targetAgnle = degrees - (degrees % (45 / div))
        if (targetAgnle !== this.currentAngle) {
          this.currentAngle = targetAgnle
        }
      }

      ;['mousemove', 'touchmove'].map(moveEventName => {
        window.addEventListener(moveEventName, onMove)
      })

      const onEnd = (endEvent) => {
        endEvent
        this.elements.angle.classList.remove('gpcr-active')
        ;['mousemove', 'touchmove'].map(moveEventName => {
          window.removeEventListener(moveEventName, onMove)
        })
        ;['mouseup', 'touchend', 'touchcancel'].map(endEventName => {
          window.removeEventListener(endEventName, onEnd)
        })
      }

      ;['mouseup', 'touchend', 'touchcancel'].map(endEventName => {
        window.addEventListener(endEventName, onEnd)
      })
    },
    onCircleTap: function(event) {
      // adjust circle position
      const dir = event.target.getAttribute('data-pos')
      if (dir !== null) { this.radialPosition = dir }
    },
    rearrangeElements: function() {
      this.picker.getRoot().app.classList.add('gpickr')

      this.elements.interaction.remove()
      this.elements.pcr.remove()

      let pcrElements = Array.from(this.picker.getRoot().app.children)
      pcrElements.map(e => {
        e.remove()
        this.elements.pcr.appendChild(e)
      })

      this.picker.getRoot().app.appendChild(this.elements.pcr)
      this.picker.getRoot().app.appendChild(this.elements.interaction)
    },
    notifyChangeToParent: function() {
      if (this.currentStops === null) { 
        this.$emit('gradient-change', null)
        return
      }
      // data send to parent
      var info = {}
      // mode
      info.mode = this.currentMode
      // angle
      if (this.currentMode === 'linear') { info.angle = this.currentAngle }
      // radialPosition
      if (this.currentMode === 'radial') { info.radialPosition = this.radialPosition }
      // stops
      info.stops = structuredClone(this.currentStops)
      info.stops.toString = function() {
        return this.gradientStopString
      }
      info.stops.toStyleString = function() {
        return this.gradientStyleString
      }
      info.stops.toSvgDef = function(id) {
        return `<${info.mode}gradient id="${id}">
          ${info.stops.map(s => {
            return `<stop stop-color="${s.color}" offset="${s.offset}" stop-opacity="${s.opacity.toFixed(2)}"></stop>`
          }).join('')}
         </${info.mode}gradient>
        `
      }
      
      this.$emit('gradient-change', info)
    },
    getMarkerStyleObject: function(markerIdx) {
      const {color, offset, opacity} = this.currentStops[markerIdx]
      return {
        left: offset,
        color: color + Math.round(opacity*255).toString(16).padStart(2, '0')
      }
    }
  },
  created: function() {
    if (this.supportConic && CSS.supports('background-image', 'conic-gradient(#fff, #fff)')) {
      this.modes.push('conic');
    }
    this.currentStops = structuredClone(this.stops)
    this.currentAngle = this.angle
    this.currentMode = this.mode
  },
  mounted: function() {
    this.picker = Pickr.create({
      el:           this.elements.button,
      theme:        'nano',
      inline:       false,
      useAsButton:  true,
      showAlways:   false,
      defaultRepresentation: 'HEXA',
      components:   {
        palette:    true,
        preview:    true,
        opacity:    true,
        hue:        true,
        interaction: {
          input: true
        }
      }
    })

    this.picker.on('change', this.onPickerChange)
    this.picker.on('init',   this.onPickerInit)
    this.picker.on('clear',  this.onPickerClear)
  },
  unmounted: function() {
    this.picker && this.picker.destroyAndRemove()
  },
  components: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.gpickr {
  flex-direction: row;
}

.gpickr.pcr-app[data-theme='nano'] {
  width: auto;
}

.pcr-app[data-theme='nano'] .pcr-interaction {
  padding: 0 0.6em 0.6em 0.6em;
}

.gpickr.pcr-app[data-theme='nano'] .pcr-interaction {
  padding: 0;
}

.gpcr-pcr {
  display: inline-flex;
  flex-direction: column;
}

.gpcr-pcr .pcr-selection, .gpcr-pcr .pcr-interaction {
  padding: 0;
}

.gpcr-interaction {
	margin-left: 0.5em;
	width: 12.5em;
	z-index: 1;
}

.gpcr-interaction .gpcr-result {
  /* @include size(7.9em, 100%); */
  /* @include transparency-background(8px); */
  width: 7.9em;
  height: 100%;
	position: relative;
	border-radius: 0.15em;
	margin-bottom: 0.5em;
	flex-grow: 1;
}

.gpcr-interaction .gpcr-result .gpcr-pos,
    .gpcr-interaction .gpcr-result .gpcr-angle {
  /* @include position(0, 0, 0, 0); */
  top: 0;
  left: 0;
	transition: all 0.3s;
	position: absolute;
	margin: auto;
	opacity: 0.25;
}

.gpcr-interaction .gpcr-result .gpcr-angle {
  /* @include size(0.35em); */
  width: 0.35em;
  height: 0.35em;
	background: white;
	border-radius: 100%;
}

.gpcr-interaction .gpcr-result .gpcr-angle > div {
  /* @include size(2px, 2em); */
  /* @include position(0, 0, 0, 50%); */
  top: 0;
  right: 0;
  bottom: 0;
  left: 50%;
  width: 2px;
  height: 2em;
	position: absolute;
	background: white;
	border-radius: 1em;
	margin: auto 0;
	transform-origin: left;
}

.gpcr-interaction .gpcr-result .gpcr-angle.gpcr-active {
	opacity: 1;
}

.gpcr-interaction .gpcr-result .gpcr-pos {
  /* @include size(5em); */
  width: 5em;
  height: 5em;
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	opacity: 1;
}

.gpcr-interaction .gpcr-result .gpcr-pos > div {
  /* @include size(15px); */
  width: 15px;
  height: 15px;
	border: 2px solid transparent;
	position: relative;
	margin: auto;
	transition: all 0.3s;
}

.gpcr-interaction .gpcr-result .gpcr-pos > div:not(.gpcr-active) {
	cursor: pointer;
}

.gpcr-interaction .gpcr-result .gpcr-pos > div::before {
  /* @include pseudo(); */
  /* @include position(0, 0, 0, 0); */
  /* @include size(5px); */
  top: 0;
  left: 0;
  width: 5px;
  height: 5px;
	border-radius: 100%;
	background: white;
	transition: all 0.3s;
	opacity: 0.25;
	margin: auto;
}

.gpcr-interaction .gpcr-result .gpcr-pos > div:hover::before {
	opacity: 1;
}

.gpcr-interaction .gpcr-result .gpcr-pos > div.gpcr-active {
	border-color: white;
	border-radius: 100%;
}

.gpcr-interaction .gpcr-result .gpcr-pos > div.gpcr-active::before {
	opacity: 1;
}

.gpcr-interaction .gpcr-result:hover .gpcr-angle {
	opacity: 1;
}

.gpcr-interaction .gpcr-mode {
  /* @include size(1.5em); */
  width: 1.5em;
  height: 1.5em;
	position: relative;
	top: 0.15em;
	left: 0.15em;
	border: 2px solid white;
	border-radius: 0.15em;
	cursor: pointer;
	opacity: 0.25;
	transition: all 0.3s;
}

.gpcr-interaction .gpcr-mode::before {
  /* @include pseudo(); */
  /* @include position(0, 0, 0, 0); */
  left: 0;
  top: 0;
	margin: auto;
	transition: all 0.3s;
}

.gpcr-interaction .gpcr-mode[data-mode=linear]::before {
  /* @include size(2px, 70%); */
  width: 2px;
  height: 70%;
	background: white;
	transform: rotate(45deg);
	border-radius: 50em;
}

.gpcr-interaction .gpcr-mode[data-mode=radial]::before {
  /* @include size(50%); */
  width: 50%;
  height: 50%;
	border-radius: 100%;
	border: 2px solid white;
}

.gpcr-interaction .gpcr-mode[data-mode=conic]::before {
  /* @include size(0); */
  width: 0px;
  height: 0px;
	border: 5px solid transparent;
	border-color: white white transparent transparent;
}

.gpcr-interaction .gpcr-mode:hover {
	opacity: 1;
}

.gpcr-stops {
	margin-bottom: 0.75em;
}

.gpcr-stops .gpcr-stop-preview {
  /* @include size(2em, 100%); */
  /* @include transparency-background(8px); */
  width: 2em;
  height: 100%;
	position: relative;
	border-radius: 0.15em;
	overflow: hidden;
	cursor: pointer;
}

.gpcr-stops .gpcr-stop-markers {
	position: relative;
	z-index: 1;
}

.gpcr-stops .gpcr-stop-markers .gpcr-marker.gpcr-active {
	border: 2px solid black;
}

.gpcr-stops .gpcr-stop-markers .gpcr-marker {
  /* @include size(12px); */
  /* @include transparency-background(4px); */
  width: 12px;
  height: 12px;
	position: absolute;
	background: currentColor;
	margin: 0.15em 0 0 -5px;
	border-radius: 100%;
	border: 2px solid white;
	box-shadow: 0 0.05em 0.2em rgba(0, 0, 0, 0.15);
	transition: opacity 0.15s;
	cursor: grab;
	cursor: webkit-grab;
}

.gpcr-stops .gpcr-stop-markers .gpcr-marker::before {
	border-radius: 100%;
}
</style>