<template>
  <div 
    style="display: flex; justify-content:center; align-items: center;"
    :style="styleObject"
  >
    <slot></slot>
    <input 
      :value="value"
      :placeholder="placeholder" 
      class="input" 
      ref="input" 
      @focus="onFocus($event)" 
      @input="onInput($event)" 
      autocomplete="off" 
      spellcheck="false"
    />
  </div>
</template>

<script>

export default {
  name: 'TextInput',
  props: {
    value: {
      default: "",
      type: String
    },
    focus: {
      default: false,
      type: Boolean
    },
    placeholder: {
      default: "",
      type: String
    }
  },
  data: function() {
    return {
      styleObject: {
      }
    }
  },
  watch: {
    focus: function() {
      if (this.focus) this.$refs.input.focus()
      else this.$refs.input.blur()
    }
  },
  components: {
  },
  methods: {
    onInput(event) {
      this.inputValue = event.target.value
      this.$emit('on-input', event)
    },
    onFocus(event) {
      this.$emit('on-focus', event)
    }
  },
  mounted: function() {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.input {
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: 20px;
  color: #2c3e50;
}

input:focus {
  outline: none;
}

input {
  outline: none;
  border: none;
  border-width: 1px;
  border-style: solid;
  border-color: #f1f1f1;
  border-radius: 5px;
}
</style>
