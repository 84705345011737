<template>
  <div 
    style="width: 100%; display: flex; flex-direction: row; align-items: center; gap: 10px;"
    :style="styleObject"
  >
    <slot></slot>
    <input 
      class="slider" 
      ref="input" 
      type="range"
      :value="value" 
      :max="max"
      :min="min"
      :step="step"
      @input="onInput($event)" 
    />
  </div>
</template>

<script>

export default {
  name: 'SlideInput',
  props: {
    value: {
      default: 0,
      type: Number
    },
    max: {
      default: 1.0,
      type: Number
    },
    min: {
      default: 0.0,
      type: Number
    },
    step: {
      default: 0.1,
      type: Number
    }
  },
  data: function() {
    return {
      styleObject: {
      }
    }
  },
  components: {
  },
  methods: {
    onInput(event) {
      this.$emit('on-input', event)
    }
  },
  mounted: function() {
    this.$refs.input.focus()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input.slider {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  appearance: none;
  width: calc(100% - 4px);
  cursor: pointer;
  background: #f1f1f1;
  border-radius: 10px;
  height: 20px;
  padding: 0 2px 0 2px;
}

input.slider:focus {
  outline: none;
}

input.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  outline: black;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 0 1px 1px #ddd;
  border: 0.5px solid #ccc;
}
</style>
