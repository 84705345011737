<template>
  <base-stack
    :gap="0"
    :direction="layout"
    style="width: 300px;"
  >
    <!-- svg preview -->
    <div
      ref="svgBackground"
      class="edit-background"
      :style='{width: width + "px", height: height + "px"}'
    >
      <div ref="svgCanvas"></div>
    </div>
    <!-- end of svg preview -->
    <!-- variant selector -->
    <horizontal-scroll-view
      style="align-self: stretch; max-width: 100%; padding: 10px 20px 20px 20px; box-sizing: border-box;"
    >
      <h-stack
        style="width: auto; justify-content: flex-start; box-sizing: border-box;"
        :gap="5"
      >
        <grid-item
          style="border: none;"
          v-for="(icon, state) in (item && item.variants)"
          :width="50"
          :height="60"
          :padding="5"
          :key="state"
          :active="state === variant"
          @click="onClickVariant($event, state)"
        >
          <v-stack
            style="justify-content: center;"
          >
            <image-preview
              :width="30"
              :height="30"
              :url="icon.url"
            ></image-preview>
            <text-label
              hierachy="caption"
              style="white-space: nowrap;"
            >
              {{ state }}
            </text-label>
          </v-stack>
        </grid-item>
      </h-stack>
    </horizontal-scroll-view>
    <!-- end of variant selector -->
    <vertical-scroll-view
      style="height: auto; flex-grow: 1; align-self: stretch; justfy-self: stretch; min-height: 100px;"
    >
      <!-- svg properties -->
      <v-stack
        v-if="selectedElement !== null" 
        style="padding-left: 20px; padding-right: 20px;"
      >
        <!-- 类型 -->
        <h-stack style="align-items: center; justify-content: start; min-height: 40px;">
          <text-label class="property-name" hierachy="caption">类型</text-label>
          <text-label style="padding-left: 20px;" hierachy="paragraph">{{ this.selectedElementType || '无' }}</text-label>
        </h-stack>
        <!-- 不透明度 -->
        <h-stack style="align-items: center; justify-content: start; min-height: 40px;">
          <text-label class="property-name" hierachy="caption">不透明度</text-label>
          <slide-input :value="selectedElementOpacity" style="padding-left: 20px;" @on-input="onOpacityChange">
            <text-label hierachy="paragraph">{{ selectedElementOpacity.toFixed(2) }}</text-label>
          </slide-input>
        </h-stack>
        <h-stack
          style="justify-contents: space-between;"
        >
          <!-- 纯色填充 -->
          <h-stack v-if="selectedElementFillColorType === 'color'" style="align-items: center; justify-content: start; min-height: 40px;" >
            <text-label class="property-name" hierachy="caption">纯色填充</text-label>
            <color-picker
              style="padding-left: 20px;"
              :color="selectedElementFillColor"
              :opacity="selectedElementFillOpacity"
              :key="'fill-color-picker'"
              @color-change="onFillColorChange"
            ></color-picker>
          </h-stack>
          <!-- 渐变填充 -->
          <h-stack v-if="selectedElementFillColorType === 'gradient'" style="align-items: center; justify-content: start; min-height: 40px;" >
            <text-label class="property-name" hierachy="caption">渐变填充</text-label>
            <gradient-picker
              style="padding-left: 20px;"
              v-if="selectedElementFillColorType === 'gradient'"
              :stops="selectedElementFillGradient"
              :angle="selectedElementFillLinearGradientAngle"
              :option="{angleAdjustment: false}"
              @gradient-change="onFillGradientChange"
            >
            </gradient-picker>
          </h-stack>
          <!-- 描边颜色 -->
          <h-stack v-if="selectedElementStrokeColorType === 'color'" style="align-items: center; justify-content: end; min-height: 40px;">
            <text-label class="property-name" hierachy="caption">描边颜色</text-label>
            <color-picker
              style="padding-left: 20px;"
              :color="selectedElementStrokeColor"
              :opacity="selectedElementStrokeOpacity"
              :key="'stroke-color-picker'"
              @color-change="onStrokeColorChange"
            ></color-picker>
          </h-stack>
        </h-stack>
        <!-- 描边粗细 -->
        <h-stack style="align-items: center; justify-content: start; min-height: 40px;">
          <text-label class="property-name" hierachy="caption">描边粗细</text-label>
          <slide-input 
            :max="2.16" 
            :min="1.0" 
            :step="0.29" 
            :value="selectedElementStrokeWidth" 
            style="padding-left: 20px;" 
            @on-input="onStrokeWidthChange"
          >
            <text-label hierachy="paragraph">{{ selectedElementStrokeWidth.toFixed(2) }}</text-label>
          </slide-input>
        </h-stack>
        <!-- 混合 -->
        <h-stack style="align-items: center; justify-content: start; min-height: 40px;">
          <text-label class="property-name" hierachy="caption">混合</text-label>
          <text-label style="padding-left: 20px;" hierachy="paragraph">{{ this.selectedElementBlendStyle || '无' }}</text-label>
        </h-stack>
      </v-stack>
      <!-- end of svg properties -->
    </vertical-scroll-view>
    <!-- actions -->
    <push-button 
      v-if="item !== null"
      class="copy"
      style="margin-bottom: 20px;"
      @click="onShowPixelateModal()"
    >
      <text-label hierachy="paragraph">像素化</text-label>
    </push-button>

    <Teleport to="#app">
      <div v-show="showPixelateModal" class="modal pixelate background">
        <h-stack class="container root" :gap="20">
          <!-- original svg -->
          <div class="container sheet">
            <text-label hierachy="heading1">原设计稿</text-label>
            <v-stack :gap="3">
              <h-stack style="justify-content: flex-start; align-items: center; gap: 30px;">
                <text-label hierachy="caption">背景颜色</text-label>
                <text-label hierachy="paragraph">white</text-label>
              </h-stack>
              <h-stack style="justify-content: flex-start; align-items: center; gap: 30px;">
                <text-label hierachy="caption">原始尺寸</text-label>
                <text-label hierachy="paragraph">{{ pixelateOriginWidth }} x {{ pixelateOriginHeight }}</text-label>
              </h-stack>
            </v-stack>
            <canvas ref="pixelateCanvasOrigin" width="240" height="240" style="border: solid 1px #efefef;"></canvas>
          </div>
          <!-- pixelated svg -->
          <div class="container sheet">
            <text-label hierachy="heading1">像素化</text-label>
            <h-stack style="justify-content: space-between; align-items: center; gap: 20px;">
              <v-stack :gap="3" style="justify-content: flex-start;">
                <!-- <h-stack style="justify-content: flex-start; align-items: center; gap: 30px; min-height: 20px;">
                  <text-label hierachy="caption" style="white-space: nowrap;">圆整阶数</text-label>
                  <h-stack style="justify-content: flex-start; align-items: center;">
                    <single-check value="0" @click.prevent="onChoosePixelateRoundness($event)">0</single-check>
                    <single-check value="1" @click.prevent="onChoosePixelateRoundness($event)">1</single-check>
                    <single-check value="2" @click.prevent="onChoosePixelateRoundness($event)">2</single-check>
                  </h-stack>
                </h-stack> -->
                <h-stack style="justify-content: flex-start; align-items: center; gap: 30px; min-height: 20px;">
                  <text-label hierachy="caption" style="white-space: nowrap;">像素阈值</text-label>
                  <drag-slider @change="onPixelateThresholdChange" :defaultValue="pixelateThreshold"></drag-slider>
                </h-stack>
                <h-stack style="justify-content: flex-start; align-items: center; gap: 30px; min-height: 20px;">
                  <text-label hierachy="caption" style="white-space: nowrap;">图标尺寸</text-label>
                  <h-stack :gap="5" style="justify-content: flex-start; align-items: center;">
                    <drag-slider @change="onPixelateSizeChange" :defaultValue="24" :from="2" :to="40"></drag-slider>
                  </h-stack>
                </h-stack>
              </v-stack>
              <canvas ref="pixelateCanvasResult" style="border: solid 1px #efefef;"></canvas>
            </h-stack>
            
            <div class="dotmatrix" :style="{'grid-template-columns': `repeat(${pixelateColCount}, 1fr)`}">
              <template v-for="r in pixelateRowCount" :key="r.id">
                <div class="pixel" v-for="c in pixelateColCount" :index="(r-1) * pixelateColCount + (c-1)" :key="c.id" @click="$event.currentTarget.classList.toggle('lid')"></div>
              </template>
            </div>
            <h-stack style="align-items:center;">
              <drop-down :defaultValue="'JSON'" :options="['JSON']"></drop-down>
              <push-button
                style="flex-grow: 1; margin: 0;"
                @click="onDownloadPixelate()"
               >
                <text-label hierachy="paragraph">下载</text-label>
              </push-button>
            </h-stack>
          </div>
          <push-button class="close-button" @click="showPixelateModal = false"  style="margin: 0px;"><text-label hierachy="paragraph">关闭</text-label></push-button>
        </h-stack>
      </div>
    </Teleport>
    <push-button 
      v-if="item !== null"
      class="copy"
      style="margin-bottom: 20px;"
      @click="onCopy()"
    >
      <text-label hierachy="paragraph">拷贝到剪切板</text-label>
    </push-button>
    <push-button 
      v-if="item !== null"
      class="download"
      style="margin-bottom: 20px;"
      @click="onDownload()"
    >
      <text-label hierachy="heading3">下载</text-label>
    </push-button>
  </base-stack>
</template>

<script>
import TextLabel from './TextLabel.vue'
import SlideInput from './SlideInput.vue'
import HStack from './HStack.vue'
import VStack from './VStack.vue'
import BaseStack from './BaseStack.vue'
import ColorPicker from './ColorPicker.vue'
import GradientPicker from './GradientPicker.vue'
import PushButton from './PushButton.vue'
import VerticalScrollView from './VerticalScrollView.vue'
import ImagePreview from './ImagePreview.vue'
import GridItem from './GridItem.vue'
import HorizontalScrollView from './HorizontalScrollView.vue'
import DropDown from './DropDown.vue'
// import SingleCheck from './SingleCheck.vue'
import DragSlider from './DragSlider.vue'

import { SVG } from '@svgdotjs/svg.js'

export default {
  name: 'SVGEditor',
  props: {
    layout: {
      default: "horizontal",
      type: String
    },
    width: {
      default: 250,
      type: Number
    },
    height: {
      default: 250,
      type: Number
    },
    item: Object,
  },
  watch: {
    'item': function() {
      this.variant = 'default' in this.item.variants && 'default' || Object.keys(this.item.variants)[0]
      this.loadSVG()
    }
  },
  data: function() {
    return {
      drawer: null,
      data: null,
      viewBox: null,
      variant: 'default',
      selectedElement: null,
      forceRefresh: 0,
      showPixelateModal: false,

      pixelateCanvasOrigin: null,
      pixelateCanvasMagnified: null,
      pixelateContextOrigin: null,
      pixelateContextMagnified: null,
      pixelateCanvasResult: null,
      pixelateContextResult: null,
      pixelateOriginWidth: 0,
      pixelateOriginHeight: 0,
      pixelateColCount: 24,
      pixelateRowCount: 24,
      pixelateImage: null,
      pixelateThreshold: 254
    }
  },
  computed: {
    name: function () { return this.item && this.item.name },
    url: function () { return this.item && this.item.variants[this.variant].url },
    shouldShowToolPopup: function () { return this.selectedElement !== null },
    shouldResponseToHover: function () { return true },
    shouldResponseToLeave: function () { return true },
    shouldResponseToClick: function () { return true },
    selectedElementOpacity: function() {
      this.forceRefresh
      if (this.selectedElement !== null) {
        const opacity = this.selectedElement.getAttribute('opacity')
        if (opacity === null) { return 1.0 } 
        else { return Number(opacity) }
      }
      return 1.0
    },
    selectedElementFillColor: function() {
      return this.selectedElement && this.selectedElement.getAttribute("fill") || null
    },
    selectedElementFillOpacity: function() {
      const opacity = this.selectedElement && this.selectedElement.getAttribute("fill-opacity")
      if (opacity === null) { return 1.0 }
      else { return Number(opacity) }
    },
    selectedElementFillGradient: function() {
      // url(#paint0_linear_69_4119)
      let url = this.selectedElement && this.selectedElement.getAttribute("fill") || null
      // #paint0_linear_69_4119
      let id  = url && url.substring(4, url.length - 1) || null
      // <lineargradient/radialgradient>
      let gdef = id && this.drawer.findOne(id) || null
      return url && id && gdef && gdef.children().map(stop => {
        const o = stop.attr('offset')
        const c = stop.attr('stop-color')
        const a = stop.attr('stop-opacity')
        return {
          color: c,
          offset: typeof o === 'number' ? `${Math.round(o*100)}%` : o,
          opacity: a
        }
      })
    },
    selectedElementFillLinearGradientAngle: function() {
      // url(#paint0_linear_69_4119)
      let url = this.selectedElement && this.selectedElement.getAttribute("fill") || null
      // #paint0_linear_69_4119
      let id  = url && url.substring(4, url.length - 1) || null
      // <lineargradient/radialgradient>
      let gdef = id && this.drawer.findOne(id) || null
      const x1 = gdef && gdef.attr("x1") || null
      const x2 = gdef && gdef.attr("x2") || null
      const y1 = gdef && gdef.attr("y1") || null
      const y2 = gdef && gdef.attr("y2") || null
      return x1 && x2 && y1 && y2 && Math.abs((Math.atan2(x1 - x2, y1 - y2)) * 180 / Math.PI) - 90
    },
    selectedElementFillColorType: function() {
      let fill = this.selectedElement && this.selectedElement.getAttribute("fill") || null
      return (fill && fill.substr(0, 3).toLowerCase() === 'url' && 'gradient') || 'color'
    },
    selectedElementStrokeColor: function() {
      return this.selectedElement && this.selectedElement.getAttribute("stroke") || null
    },
    selectedElementStrokeOpacity: function() {
      const opacity = this.selectedElement && this.selectedElement.getAttribute("stroke-opacity")
      if (opacity === null) { return 1.0 }
      else { return Number(opacity) } 
    },
    selectedElementStrokeWidth: function() {
      this.forceRefresh
      if (this.selectedElement === null) {
        return 1.0
      } else {
        const width = this.selectedElement.getAttribute("stroke-width")
        if ( width === null ) { return 1.0 }
        else { return Number(width) }
      }
    },
    selectedElementStrokeColorType: function() {
      return this.selectedElementStrokeColor && (this.selectedElementStrokeColor.slice(0, 3) === 'url' ? 'gradient' : 'color') || 'color'
    },
    selectedElementBlendStyle: function() {
      return this.selectedElement && this.selectedElement.getAttribute('style') || null
    },
    selectedElementType: function() {
      return this.selectedElement && this.selectedElement.tagName || null
    }
  },
  methods: {
    makeHightlight(element, temperory = true) {
      if (temperory) return

      let id = temperory ? 'SvgjsSvgTemporaryHighlighter' : 'SvgjsSvgHighlighter'

      // set strokeWidth to 1px
      let strokeWidth = (Math.max(this.viewBox[2], this.viewBox[3]) / Math.max(this.width, this.height)).toPrecision(4)

      var highlight = SVG(element.outerHTML)
      highlight.attr('id', id)
      highlight.attr('opacity', '1.0')
      highlight.attr('fill', null)
      highlight.attr('stroke', '#000')
      highlight.attr('stroke-width', strokeWidth)
      highlight.attr('stroke-dasharray', '2 2')
      highlight.attr('stroke-opacity', '1')
      highlight.attr('stroke-dashoffset', '0')
      highlight.attr('style', null)

      let animation = SVG('<animate attributeName="stroke-dashoffset" values="0;4" dur="1s" repeatCount="indefinite" />')

      highlight.add(animation)

      this.drawer.first().add(highlight, 100)
    },
    deselectAll() {
      this.drawer.find('#SvgjsSvgHighlighter').map(node => node.remove())
      this.selectedElement = null
    },
    onDeselectAll(event) {
      event
      // remove highligher
      this.deselectAll()
      this.$refs.svgBackground.removeEventListener('click', this.onDeselectAll)
    },
    onHoverElement(event) {
      if (this.shouldResponseToHover && event.target !== this.selectedElement) {
        this.makeHightlight(event.target)
      }
    },
    onClickElement(event) {
      event.stopPropagation()
      event.preventDefault()

      if (this.shouldResponseToClick) {
        this.deselectAll()

        if (event.target !== this.selectedElement) {
          this.selectedElement = event.target
          this.makeHightlight(event.target, false)
          // detect clicking outside
          this.$refs.svgBackground.addEventListener('click', this.onDeselectAll)
        } else {
          this.deselectAll()
        }
      }
    },
    onClickVariant(event, item) {
      event
      this.variant = item
      this.loadSVG()
    },
    onLeaveElement() {
      if (this.shouldResponseToLeave) {
        this.drawer.find('#SvgjsSvgTemporaryHighlighter').map(node => node.remove() )
      }
    },
    onFillColorChange(color, hex) {
      if (this.selectedElement === null) { return }

      this.selectedElement.setAttribute('fill', hex)
      this.selectedElement.setAttribute('fill-opacity', color[color.length-1])
    },
    onFillGradientChange(gradient) {

      if (gradient === null) {
        this.selectedElement.removeAttribute('fill')
        return
      }

      // change to a new ID everytime so Safari is happy
      this.onFillGradientChange.callTimes = this.onFillGradientChange.callTimes && this.onFillGradientChange.callTimes+1 || 1
      // url(#some_id_string)
      let url  = this.selectedElement && this.selectedElement.getAttribute("fill") || null
      // some_id_string
      let id   = url && url.substring(5, url.length - 1) || null
      // <lineargradient/radialgradient>
      let gdef = id && this.drawer.defs().findOne(`#${id}`) || null
      // linear gradient vector length
      let x1 = gdef.attr("x1")
      let x2 = gdef.attr("x2")
      let y1 = gdef.attr("y1")
      let y2 = gdef.attr("y2")
      // gradient uni
      let units = gdef.attr("gradientUnits")
      // <defs></defs>
      let defs = gdef.parent()
      // remve old gradient
      gdef.remove()

      // create new gradient with unique new id
      const newID = `MijiaIcon_${gradient.mode}_${this.onFillGradientChange.callTimes}`
      let g = SVG(gradient.stops.toSvgDef(newID))

      g.attr('x1', x1)
      g.attr('x2', x2)
      g.attr('y1', y1)
      g.attr('y2', y2)

      g.attr("gradientUnits", units)

      // reference selected element fill to the new definition
      this.selectedElement.setAttribute("fill", `url(#${newID})`)
      // append new gradient definition to <defs>
      defs.add(g)
    },
    onStrokeColorChange(color, hex) {
      if (this.selectedElement === null) { return }

      this.selectedElement.setAttribute('stroke', hex)
      this.selectedElement.setAttribute('stroke-opacity', color[color.length-1])
    },
    onOpacityChange(event) {
      if (this.selectedElement === null) { return }

      const opacity = Number(event.target.value)
      this.selectedElement.setAttribute('opacity', opacity)

      this.forceRefresh += 1
    },
    onStrokeWidthChange(event) {
      if (this.selectedElement === null) { return }

      const opacity = Number(event.target.value)
      this.selectedElement.setAttribute('stroke-width', opacity)

      this.forceRefresh += 1
    },
    loadSVG () {
      // Clear the SVG canvas
      this.$refs.svgCanvas.innerHTML = ''
      // Create new SVG
      this.drawer = SVG().addTo(this.$refs.svgCanvas).size(this.width, this.height)
      // pull data from the server
      this.url && fetch(this.url)
        .then(r => r.text())
        .then(d => {
          this.data = d
          this.drawer.svg(this.data)
          let viewBox = this.drawer.first().attr('viewBox').trim().split(/\s/).map(n => { return Number(n) })
          const l = (0.1 * Math.max(viewBox[2], viewBox[3])).toPrecision(2)
          viewBox[1] = viewBox[0] -= l
          viewBox[3] = viewBox[2] += 2 * l
          this.viewBox = structuredClone(viewBox)
          // set a bigger viewbox and put shape at the center
          this.drawer.attr('viewBox', viewBox.join(' '))

          this.drawer.attr('width', this.width)
          this.drawer.attr('height', this.height)
          this.drawer.find('path,circle,rect,ellipse').map(node => {
            node.on('mouseover', this.onHoverElement)
            node.on('mouseout', this.onLeaveElement)
            node.on('click', this.onClickElement)
          })
        })
    },
    onCopy() {
      this.deselectAll()
      navigator.clipboard.writeText(this.drawer.svg());
    },
    onDownloadPixelate() {
      var data = {
        width: this.pixelateColCount,
        height: this.pixelateRowCount,
        data: []
      }
      let pixels = document.querySelectorAll('.dotmatrix > .pixel.lid')
      for(const p of pixels) {
        data.data.push(parseInt(p.getAttribute('index')))
      }

      var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data))
      var el = document.createElement('a')
      el.setAttribute("href",dataStr)
      el.setAttribute("download", `${this.name}.json`)
      el.click()
    },
    onDownload() {
      this.deselectAll()
      var el = document.createElement('a')
      el.style.dispaly = 'none'
      // file name
      el.setAttribute('download', `${this.name}.svg`)
      // file content
      el.setAttribute('href', `data:application/svg;charset=utf-8,${encodeURIComponent(this.drawer.first().svg())}`)

      el.click()
    },
    async onShowPixelateModal() {
      this.showPixelateModal = true

      this.pixelateCanvasOrigin = this.$refs.pixelateCanvasOrigin;
      this.pixelateCanvasResult = this.$refs.pixelateCanvasResult;

      // get context from canvas
      this.pixelateContextOrigin    = this.pixelateCanvasOrigin.getContext('2d', { willReadFrequently: true });
      this.pixelateContextResult    = this.pixelateCanvasResult.getContext('2d', { willReadFrequently: true });


      this.pixelateCanvasResult.width = this.pixelateColCount
      this.pixelateCanvasResult.height = this.pixelateRowCount

      const loadImage = async () => {
        return new Promise((resolve) => {
          let img = new Image()
          img.onload = () => {
            resolve(img)
          }
          // conver svg to urlencodestring
          img.src = `data:image/svg+xml;charset=utf-8,${encodeURIComponent(this.drawer.first().svg())}`
        })
      }

      this.pixelateImage = await loadImage()

      this.pixelateOriginWidth = this.pixelateImage.width
      this.pixelateOriginHeight = this.pixelateImage.height

      this.$nextTick(() => {
        this.pixelate()
      })
    },
    pixelate() {
      this.pixelateCanvasResult.width = this.pixelateColCount
      this.pixelateCanvasResult.height = this.pixelateRowCount

      // draw the origin image
      this.pixelateContextOrigin.clearRect(0, 0, this.pixelateCanvasOrigin.width, this.pixelateCanvasOrigin.height)
      this.pixelateContextOrigin.drawImage(this.pixelateImage, 0, 0, this.pixelateCanvasOrigin.width, this.pixelateCanvasOrigin.height)
      
      // draw to the smaller canvas
      this.disableSmoothRendering(this.pixelateContextResult)
      this.pixelateContextResult.clearRect(0, 0, this.pixelateCanvasResult.width, this.pixelateCanvasResult.height)
      this.pixelateContextResult.drawImage(this.pixelateImage, 0, 0, this.pixelateColCount, this.pixelateRowCount)
      
      this.$nextTick(() => {
        const dotmatrix = document.querySelector('.dotmatrix')
        const pixels = this.pixelateContextResult.getImageData(0, 0, this.pixelateCanvasResult.width, this.pixelateCanvasResult.height).data
        for (let i = 0, o = 0; i < pixels.length; i += 4, o += 1) {
          const [r, g, b, a] = pixels.slice(i, i + 4)
          if (a !== 0 && r + g + b < this.pixelateThreshold * 3) {
            dotmatrix.querySelector(`.pixel[index="${o}"]`).classList.add('lid')
          } else {
            dotmatrix.querySelector(`.pixel[index="${o}"]`).classList.remove('lid')
          }
        }
      })
    },
    onChoosePixelateRoundness(event) {
      event.currentTarget.classList.toggle('active')
    },
    onPixelateThresholdChange(threshold) {
      this.pixelateThreshold = threshold

      this.pixelate()
    },
    onPixelateSizeChange(value) {
      this.pixelateColCount = value
      this.pixelateRowCount = value

      this.pixelate()
    },
    disableSmoothRendering: function(context) {
      context.webkitImageSmoothingEnabled = false;
      context.mozImageSmoothingEnabled = false;
      context.msImageSmoothingEnabled = false;
      context.imageSmoothingEnabled = false;
      return context
    },
  },
  mounted: function () {
    this.loadSVG()
  },
  components: {
    TextLabel,
    SlideInput,
    HStack,
    VStack,
    BaseStack,
    ColorPicker,
    GradientPicker,
    PushButton,
    VerticalScrollView,
    ImagePreview,
    GridItem,
    HorizontalScrollView,
    DropDown,
    // SingleCheck,
    DragSlider
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.opacity-input {
  padding-left: 20px;
}

.opacity-input >>> input {
  font-size: 14px;
  font-weight: 300;
  line-height: 16px;
  margin: 0;
}

.property-name {
  min-width: 50px;
  text-align: left;
}

.button.download {
  background: #f1f1f1;
  align-self: stretch;
}

.button.download:hover {
  background: #e1e1e1;
}

.button.copy {
  align-self: stretch;
}

.button {
  margin: 0 20px 0 20px;
}

.edit-background {
  padding: 25px;
  background-image: url('@/assets/chessboard_pattern.svg');
}
.bordered {
  border-style: solid;
  border-width: 1px;
  border-color: #f1f1f1;
}

.grid-item.active {
  background: #f1f1f1;
}

.modal.pixelate.background {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top : 0;
  background-color: rgba(0, 0, 0, 0.4);

  display: flex;
  align-items: center;

  z-index: 9999;
}

.modal.pixelate > .container.root {
  position: relative;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.modal.pixelate > .container.root > .container.sheet {
  position: relative;
  box-sizing: border-box;
  padding: 30px;
  border-radius: 10px;
  border: solid 1px #ccc;
  background-color: white;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-start;
}

.dotmatrix {
  display: grid;
  flex-direction: row;
  gap: 1px;
  flex-wrap: wrap;
}

.dotmatrix > .pixel {
  width: 15px;
  height: 15px;
  background-color: white;
  box-sizing: border-box;
  border: solid 1px #efefef;
  cursor: pointer;
}

.dotmatrix > .pixel.lid {
  background-color: black;
}

.container.root > .button.push:hover {
  background-color: #f1f1f1;
}
</style>
