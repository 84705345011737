<template>
  <div>
    <canvas
      ref="canvas"
      :width="width"
      :height="height"
      :alt="alt"
    >
    </canvas>
  </div>
</template>

<script>

export default {
  name: 'DotImagePreview',
  props: {
    url: String,
    width: {
      default: 64,
      type: Number
    },
    height: {
      default: 64,
      type: Number
    },
    alt: String,
    pixelGap: {
      default: 4,
      type: Number
    }
  },
  data: function() {
    return {
      content: null
    }
  },
  watch: {
    content: function () {
      this.updateCanvas()
      this.draw()
    },
    url: function () {
      this.loadImage()
    }
  },
  methods: {
    updateCanvas: function () {
      this.$refs.canvas.width = this.content.form.widthPixel * this.pixelGap
      this.$refs.canvas.height = this.content.form.heightPixel * this.pixelGap
      this.$refs.canvas.getContext('2d').imageSmoothingEnabled = false
      this.$refs.canvas.getContext('2d').lineWidth = 10
    },
    draw () {
      console.log('updating canvas')
      if (this.content === null) { return }
      const canvas      = this.$refs.canvas
      const context     = canvas.getContext('2d')
      const imageData   = context.getImageData(0, 0, canvas.width, canvas.height)
      for (var y = 0; y < this.content.dotList.length; y++) {
        for (var x = 0; x < this.content.dotList[y].length; x++) {
          imageData.data[4 * 16 * this.pixelGap * y * this.pixelGap + 4 * x * this.pixelGap + 0] = this.content.dotList[y][x][0]
          imageData.data[4 * 16 * this.pixelGap * y * this.pixelGap + 4 * x * this.pixelGap + 1] = this.content.dotList[y][x][1]
          imageData.data[4 * 16 * this.pixelGap * y * this.pixelGap + 4 * x * this.pixelGap + 2] = this.content.dotList[y][x][2]
          imageData.data[4 * 16 * this.pixelGap * y * this.pixelGap + 4 * x * this.pixelGap + 3] = parseInt(this.content.dotList[y][x][3] * 255)
        }
      }
      context.putImageData(imageData, 0, 0);
    },
    loadImage: function () {
      fetch(this.url)
        .then( res => res.json() )
        .then( json => {
          this.content = json
        })
    }
  },
  mounted: function () {
    this.loadImage()
  },
  components: {
  }
}
</script>

<style scoped>

</style>