<template>
  <!-- <text-label hierachy="heading2" style="position: absolute; left: 10px; top: 10px;">vi: #{{ currentVisibleVirtualIndex }} vr: {{ currentVirtualRound }} vc: {{virtualRowsPerRound}}</text-label>
  <text-label hierachy="heading2" style="position: absolute; left: 10px; top: 30px;">
    <template v-for="index in virtualRowsPerRound">
      {{ calcRowTopOffset(index-1) }}px 
    </template>
  </text-label>
  <text-label hierachy="heading2" style="position: absolute; left: 10px; top: 50px;">scroll: {{ scrollTop }}px container: {{ virtualContainerHeight }}</text-label> -->
  <div class="lazy-grid wrapper">
    <div
      class="lazy-grid container"
      :style="containerStyleObject"
    >
      <div 
        v-for="rowIndex in virtualRowsPerRound" 
        :class="`lazy-grid row row-${rowIndex-1}`"
        :key="`lazy-grid-row-${rowIndex-1}`"
        :style="{'top': `${calcRowTopOffset(rowIndex-1)}px`}"
        ref="row"
      >
        <grid-item
          v-for="(item, index) in getItemsForRow(rowIndex-1)"
          :key="item.name+index"
          :width="itemWidth"
          :height="itemHeight"
          :padding="itemPadding"
          :class="{'space-holder': item === null}"
          @click="onClick($event, item)"
        >
          <v-stack>
            <image-preview
              :width="imageWidth"
              :height="imageHeight"
              :url="item && (item.variants.default || Object.values(item.variants)[0]).url"
              :alt="item && item.name"
              v-if="item && item.filetype === 'svg'"
            > </image-preview>
            <dot-image-preview
              :width="imageWidth"
              :height="imageHeight"
              :url="item && (item.variants.default || Object.values(item.variants)[0]).url"
              :alt="item && item.name"
              v-else-if="item && item.filetype === 'json'"
            >
            </dot-image-preview>
            <text-label v-if="showTitle" hierachy="caption">{{ item && item.name }}</text-label>
            <text-label v-if="showKeywords" hierachy="footnote">{{ item && item.keywords.join(', ') }}</text-label>
          </v-stack>
        </grid-item>
      </div>
    </div>
  </div>
</template>

<script>
import GridItem from './GridItem.vue'
import TextLabel from './TextLabel.vue'
import VStack from './VStack.vue'
import ImagePreview from './ImagePreview.vue'
import DotImagePreview from './DotImagePreview.vue'

export default {
  name: 'LazyGrid',
  emits: ['on-select-item'],
  props: {
    items: Array,
    scrollTop: {
      default: null,
      type: Number
    },
    showTitle: {
      default: true,
      type: Boolean
    },
    showKeywords: {
      default: true,
      type: Boolean
    },
    itemWidth: {
      default: 100,
      type: Number
    },
    itemHeight: {
      default: 120,
      type: Number
    },
    imageWidth: {
      default: 64,
      type: Number
    },
    imageHeight: {
      default: 64,
      type: Number
    },
    itemPadding: {
      default: 20,
      type: Number
    }
  },
  data: function() {
    return {
      boundingRect: null
    }
  },
  watch: {
    items: function() {
      this.$nextTick(() => {
        this.onWindowResize()
      })
    }
  },
  computed: {
    itemClientSize: function () {
      return {
        width: this.itemWidth + 2 * this.itemPadding,
        height: this.itemHeight + 2 * this.itemPadding + 1 // 1 px border width
      }
    },
    containerStyleObject: function() {
      return {
        height: `${this.virtualContainerHeight}px`,
        width: "100%"
      }
    },
    virtualContainerHeight: function () {
      const rows = this.items && Math.ceil(this.items.length / this.visibleItemPerRow) || 0
      return rows * this.itemClientSize.height
    },
    virtualRowsPerRound: function () {
      return this.boundingRect && Math.ceil(this.boundingRect.height / this.itemClientSize.height) + 1 || 0
    },
    virtualRowHeight: function () {
      return this.visibleItemPerCol * this.itemClientSize.height
    },
    // split entire container into round of [1 2 3] [1 2 3] [1 2 3] [1 ...
    // this property gives the round index of which the viewport 
    // is currently looking at
    currentVirtualRound: function () {
      const round = Math.floor(this.scrollTop / (this.virtualRowHeight * this.virtualRowsPerRound))
      // scrollTop can be negative on some platform
      return Math.max(round, 0)
    },
    currentVisibleVirtualIndex: function () {
      const offsetTopInRound = this.scrollTop % (this.virtualRowHeight * this.virtualRowsPerRound)
      const rowIDofHighestRowInRound  = Math.floor(offsetTopInRound / this.virtualRowHeight)
      // scrollTop can be negative on some platform
      return Math.max(rowIDofHighestRowInRound, 0)
    },
    visibleItemPerRow: function () {
      return this.boundingRect && Math.floor(this.boundingRect.width / this.itemClientSize.width) || 0
    },
    visibleItemPerCol: function () {
      if (this.boundingRect === null) return 0
      return 1
    },
    visibleItemCount: function () {
      return this.visibleItemPerRow * this.visibleItemPerCol
    }
  },
  methods: {
    calcRowTopOffset: function (rowIndex) {
      // Note: If you are maintaining this project
      // and find this section really hard to understand,
      // the following value tabel may help.
      // round	virtual_index	top_0	  top_1	  top_2
      // 0	    0	            0round  0round  0round 
      // 0	    1	            1round  0round  0round 
      // 0	    2	            1round  1round  0round 
      // 1	    0	            1round  1round  1round 
      // 1	    1	            2round  1round  1round 
      // 1	    2	            2round  2round  1round 
      // 2	    0	            2round  2round  2round 
      // 2	    1	            3round  2round  2round 
      // 2	    2	            3round  3round  2round 
      // 0	    0	            3round  3round  3round 
      // …	    …	            …	    …	    …
      var top = 0
      if (rowIndex >= this.currentVisibleVirtualIndex) {
        top = this.virtualRowsPerRound * this.currentVirtualRound * this.virtualRowHeight
      } else {
        // append to bottom
        top = this.virtualRowsPerRound * (this.currentVirtualRound + 1) * this.virtualRowHeight
      }
      // move it back, if page exceeds the container
      if (top + rowIndex * this.itemClientSize.height >= this.virtualContainerHeight) {
        top -= this.virtualRowsPerRound * this.virtualRowHeight
      }
      return top
    },
    getItemsForRow: function (rowIndex) {
      const offset = Math.floor(this.calcRowTopOffset(rowIndex) / this.virtualRowHeight) + rowIndex
      return this.items && this.items.slice(offset * this.visibleItemCount, (offset + 1) * this.visibleItemCount)
    },
    onClick: function(event, item) {
      this.$emit('on-select-item', item)
    },
    onWindowResize: function() {
      this.boundingRect = document.querySelector(".lazy-grid.wrapper").getBoundingClientRect()
    }
  },
  mounted: function () {
    window.addEventListener('resize', this.onWindowResize)
  },
  unmounted: function() {
    window.removeEventListener('resize', this.onWindowResize)
  },
  components: {
    GridItem,
    TextLabel,
    VStack,
    ImagePreview,
    DotImagePreview
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .space-holder {
    opacity: 0.0;
    pointer-events: none;
  }

  .lazy-grid.wrapper {
    max-height: 100%;
    max-width: 100%;
  }

  .lazy-grid.container {
    width: 100%;
  }

  .lazy-grid.row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
  }

  @keyframes fadein {
    from { 
      opacity: 0;
      scale: 0.5;
      }
    to   { 
      opacity: 1;
      scale: 1.0;
      }
  }
  .lazy-grid .grid-item {
    animation: 0.1s ease-in-out 0s 1 fadein;
  }

  /* .page-0 {
    background: white;
  }

  .page-1{
    background: red;
  }

  .page-2 {
    background: green;
  }

  .page-3 {
    background: yellow;
  }

  .page-4{
    background: brown;
  }

  .page-5 {
    background: gray;
  }

  .page-6 {
    background: darkorange;
  } */
</style>
