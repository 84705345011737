const servers = {
  api: {
    url: "https://icons.luoruiyao.cn/api/",
    // url: "http://hacmini.local:3000/api/",
  },
  file: {
    url: "https://icons.luoruiyao.cn/files/"
    // url: "http://hacmini.local:8080/icons/file/"
  }
}

const apis = {
  list: {
    url: new URL("list", servers.api.url)
  },
  listCategories: {
    url: new URL("list/categories", servers.api.url)
  }
}

module.exports = {
  servers,
  apis
}