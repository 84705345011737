<template>
  <div 
    class="drop-down"
    :class="{'selected': (defaultValue !== null || selection !== null)}"
  >
    <h-stack
      class="button bordered"
      :gap="20"
      @click.stop="onOpen"
    >
      <text-label class="active-selection" hierachy="paragraph">
        {{ activeSelectionTitle }}
      </text-label>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </h-stack>

    <v-stack 
      class="option-list bordered"
      :style="{display: dropdownListDisplayMode}"
      v-click-outside="onClose"
    >
      <text-label 
        hierachy="paragraph"
        class="option"
        v-for="option in options"
        :value="option"
        :key="option.id"
        @click="onSelect"
      >
        {{ option }}
      </text-label>
    </v-stack>
  </div>
</template>

<script>

import TextLabel from './TextLabel.vue'
import VStack from './VStack.vue'
import HStack from './HStack.vue'

export default {
  name: 'DropDown',
  emits: ['on-select'],
  props: {
    options: {
      default: () => ['option 1', 'option 2'],
      type: Array
    },
    defaultValue: {
      default: null,
      type: String
    }
  },
  computed: {
    activeSelectionTitle: function () {
      if (this.selection !== null) {
        return this.selection
      } else if (this.defaultValue !== null) {
        return this.defaultValue
      } else {
        return "请选择..."
      }
    },
    dropdownListDisplayMode: function() {
      return this.active ? "flex" : "none"
    }
  },
  data: function() {
    return {
      selection: null,
      active: false
    }
  },
  methods: {
    onOpen: function () {
      this.active = true
    },
    onClose: function(event) {
      event.preventDefault()
      event.stopPropagation()
      this.active = false
    },
    onSelect: function(event) {
      this.selection = event.target.getAttribute('value')
      this.active = false
      this.$emit('on-select', this.selection)
    }
  },
  components: {
    TextLabel,
    VStack,
    HStack
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bordered {
  border: solid 1px #EFEFEF;
  border-radius: 5px;
}

.drop-down {
  position: relative;

  white-space: nowrap;
}

.drop-down > .button {
  color: #CCC;
  box-sizing: border-box;
  padding: 10px;

  cursor: pointer;

  user-select: none;

  align-items: center;
  justify-content: space-between;

  min-width: 100px;
}

.drop-down.selected > .button {
  color: black;
}

.drop-down > .button:hover {
  background-color: #EFEFEF;
}

.drop-down > .option-list {
  margin-top: 5px;
  position: absolute;
  z-index: 999;
  background-color: white;
  height: auto;
  overflow: hidden;
}

.drop-down > .option-list > .option {
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px;
  white-space: nowrap;

  background: white;

  flex-grow: 1;
  align-self: stretch;
  text-align: left;

  min-width: 100px;
}

.drop-down > .option-list > .option:hover {
  background: #EFEFEF;
}

</style>
