<template>
  <v-stack>
    <home-scene></home-scene>
  </v-stack>
</template>

<script>
import HomeScene from './scenes/HomeScene.vue'
import VStack from './components/VStack.vue'

export default {
  name: 'App',
  components: {
    VStack,
    HomeScene
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  height: 100vh;
}
.padding.top {
  height: calc(100% - 60px);
  padding-top: 60px;
}

.padding.bottom {
  height: calc(100% - 60px);
  padding-bottom: 60px;
}
</style>
