<template>
  <div @mousedown="onDragStart($event)" class="container" :class="{'active': active}">
    <text-label hierachy="caption" style="color: black; opacity: 1;">{{ value }}</text-label>
  </div>
</template>

<script>

import TextLabel from './TextLabel.vue'

export default {
  name: 'DragSlider',
  props: {
    defaultValue: {
      type: Number,
      default: 0
    },
    from: {
      type: Number,
      default: 0
    },
    to: {
      type: Number,
      default: 255
    }
  },
  data: function() {
    return {
      value: this.defaultValue,
      active: false
    }
  },
  watch: {
    value: function(newValue) {
      this.$emit('change', newValue)
    }
  },
  methods: {
    onDragStart: function() {
      // const el = event.currentTarget;
      document.body.addEventListener('mousemove', this.onDrag);

      ['mouseup', 'mouseleave'].forEach(name => {
        document.body.addEventListener(name, () => {
          document.body.removeEventListener('mousemove', this.onDrag);
        });
      });
    },
    onDrag: function(event) {
      let offset = 0
      if (event.movementX < 0) {
        offset = Math.floor(event.movementX / 3)
      } else {
        offset = Math.ceil(event.movementX / 3)
      }
      this.value = Math.max(this.from, Math.min(this.to, this.value + offset));
    },
    onDragEnd: function() {
    }
  },
  components: {
    TextLabel
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
  min-width: 60px;
  padding: 1px 10px 1px 10px;
  text-align: center;
  background-color: white;
  border-radius: 2px;

  box-sizing: border-box;

  border: solid 1px #efefef;

  line-height: 12px;

  cursor: ew-resize;

  user-select: none;
}

.container:hover,
.container.active {
  border: solid 1px #0D84FF;
}
</style>
