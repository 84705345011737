<template>
  <text-label hierachy="footnote" style="opacity: 0.2;">{{ copyright }}</text-label>
</template>

<script>
import TextLabel from './TextLabel.vue'

export default {
  name: 'GlobalFooter',
  props: {
    copyright: {
      default: "Mijia Design Group © Xiaomi Cooporation, 2022, All rights reserved.",
      type: String
    }
  },
  components: {
    TextLabel
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
