<template>
  <span :class="classObject">
    <slot></slot>
  </span>
</template>

<script>
  export default {
    /**
    * title
    * subtitle
    * heading1
    * heading2
    * heading3
    * paragraph
    * caption
    * footnote
    * @return string
    */
    props: [ 'hierachy' ],
    data () {
      return {}
    },
    computed: {
      classObject: function () {
        return {
          large: this.hierachy === 'large',
          title: this.hierachy === 'title',
          subtitle: this.hierachy === 'subtitle',
          heading1: this.hierachy === 'heading1',
          heading2: this.hierachy === 'heading2',
          heading3: this.hierachy === 'heading3',
          paragraph: this.hierachy === 'paragraph',
          caption: this.hierachy === 'caption',
          footnote: this.hierachy === 'footnote'
        }
      }
    }
  }
</script>

<style scoped>
  span.large {
    font-size: 36px;
    font-weight: 400;
    line-height: 36px;
  }
  span.title {
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
  }
  span.subtitle {
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    opacity: 0.6;
  }
  span.heading1 {
    font-size: 18px;
    font-weight: 300;
    line-height: 18px;
  }
  span.heading2 {
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
  }
  span.heading3 {
    font-size: 16px;
    font-weight: 300;
    line-height: 16px;
  }
  span.paragraph {
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
  }
  span.caption {
    font-size: 12px;
    font-weight: 600;
    line-height: 12px;
    opacity: 0.6;
  }
  span.footnote {
    font-size: 12px;
    font-weight: 300;
    line-height: 12px;
    opacity: 0.6;
  }
</style>
