<template>
  <div class="spacer filling">
  </div>
</template>

<script>

export default {
  name: 'FillingSpacer',
  props: {
  },
  data: function() {
    return {
    }
  },
  components: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.spacer.filling {
  flex-grow: 1
}
</style>
