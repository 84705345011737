<template>
  <div>
    <!-- <text-label hierachy="large">{{ title }}</text-label> -->
    <image-preview
      :width="100"
      :height="100"
      :url="require('../assets/logo.svg')"
    ></image-preview>
  </div>
</template>

<script>
// import TextLabel from './TextLabel.vue'
import ImagePreview from './ImagePreview.vue'

export default {
  name: 'GlobalHeader',
  props: {
    title: String
  },
  components: {
    // TextLabel,
    ImagePreview
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
