<template>
  <div 
    class="scrollable horizontal"
    ref="container"
    :class="classObject"
    :style="styleObject"
  >
    <slot>
    </slot>
  </div>
</template>

<script>

export default {
  name: 'HorizontalScrollView',
  props: {
    hideScrollBar: {
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      styleObject: {
      }
    }
  },
  computed: {
    classObject: function () {
      return {
        hideScrollBar: this.hideScrollBar
      }
    },
  },
  methods: {
    onScroll: function (event) {
      this.$emit('onScroll', event)
    }
  },
  mounted: function () {
    this.$refs.container.addEventListener('scroll', this.onScroll)
  },
  unmounted: function () {
  },
  components: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.scrollable.horizontal {
  overflow-x: scroll;
}

.hideScrollBar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.hideScrollBar::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
</style>
