import { createApp } from 'vue'
import App from './App.vue'

const app = createApp(App)

// create new click-outside directive on all components
app.directive('click-outside', {
  mounted: function (el, binding) {
    el.clickOutsideEvent = function (event) {
      // here I check that click was outside the el and his children
      if (!(el == event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        binding.value(event)
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
});

// mount app
app.mount('#app')
